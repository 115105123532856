export const SET_LOADER = (state, status) => {
  state.loader = status; 
};
export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};
export const SET_LOADER_NEW = (state, status) => {
  state.loaderNew = status;
};

export const SET_SETTING = (state, data) => {
  state.data = data;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};
export const SET_POSTS1 = (state, data) => {
  state.data = data.result;
  state.Total = data.total;
  state.fileNameid=[]
  for (let index = 0; index < state.data.length; index++) {
    const element = state.data[index].id;
    state.fileNameid.push(element)
  }
};

export const SET_POSTS2 = (state, data) => {
  state.data.push(...data);
  state.fileNameid=[]
  for (let index = 0; index < state.data.length; index++) {
    const element = state.data[index].id;
    state.fileNameid.push(element)
  }
};

export const SET_WEBSETTING = (state, data) => {
  state.data.phoneNumber = data.phoneNumber;
  state.data.userApp = data.userApp;
  state.data.doctorApp = data.doctorApp;
  state.data.doctorWeb = data.doctorWeb;
};

export const SET_LOGO = (state, data) => {
  state.data.logo = data.logo;
};
export const UPDATE_STATUS = (state, data) => {
  const objIndex = state.data.findIndex((obj) => obj.id === data.id);
  if(objIndex>=0){
    state.data.splice(objIndex,1)
  }
};