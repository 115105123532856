export default {
  data: [],
  total: 0,
  
  userProject:[],
  userProjectTotal:0,

  loadMore: false,
  dataLoader: false,
  loader: false,
  addUserModal:false,
  statusModal:false,
  updateUserModal:false,

  id: null,
  name: null,
  loginId: null,
  password: null,
  profile: null,
  profileName: null,
  socketId: null,
  type: null,
  status: null,
  active: null,
  ip: null,
  last_login: null,
  createdAt: null,
  updatedAt: null,
  permissions: [],
  passwordStatus: false,
};
