export default {
  data: [],
  total: 0,

  loadMore: false,
  dataLoader: false,
  loader: false,
  addUserModal:false,
  userFormData:[],

  id: null,
  name: null,
  loginId: null,
  password: null,
  profile: null,
  profileName: null,
  socketId: null,
  type: null,
  status: null,
  active: null,
  ip: null,
  last_login: null,
  createdAt: null,
  updatedAt: null,
  permissions: [],
  passwordStatus: false,
  excelData:[],

  newserialNo: null,
  newtitle: null,
  newfirstName: null,
  newlastName: null,
  newinitial: null,
  newemail: null,
  newfatherName: null,
  newdob: null,
  newgender: null,
  newprofession: null,
  newmailingStreet: null,
  newmailingCity: null,
  newmailingPostalCode: null,
  newmailingCountry: null,
  newserviceProvider: null,
  newfileNo: null,
  newreferenceNo: null,
  newsimNo: null,
  newtypeOfNetwork: null,
  newcellModelNo: null,
  newimei1: null,
  newimei2: null,
  newtypeOfPlan: null,
  newcreditCardType: null,
  newcontractValue: null,
  newdateOfIssue: null,
  newdateOfRenewal: null,
  newinstallment: null,
  newamountInWords: null,
  newremarks: null,
};
