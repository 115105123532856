import { errorHandler, successHandler } from "../../../services/_helper";
import { apiServices } from "../../../services/api.service";

// get admin
export const getAdmin = async ({ commit }, { limit, offset, search, status,role }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getAdmin(limit, offset, search, status,role).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_LOAD_MORE', response.data.total > offset + limit);
			if (+offset > 0) {
				commit('PUSH_DATA', response.data.result);
			} else {
				commit('SET_DATA', response.data);
			}
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const addAdmin = async ({ commit ,state,dispatch}, { subAdminId,subAdmin,phoneNumber,name,email,role,startDate,duration,endDate }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.addUser(subAdminId,subAdmin,phoneNumber,name,email,role,startDate,duration,endDate).then(
    () => {
      state.addUserModal=false
      dispatch('getAdmin',{limit:10,offset:0,search:"",status:"ACTIVE",role:'ADMIN'})
      commit("SET_LOADER", false, { root: true });
      successHandler('Admin Added Successfully')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};

export const StatusChange = async ({ commit }, { id,status }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.StatusChange(id,status).then(
    (response) => {
      commit("UPDATE_STATUS", response.data);
      commit("SET_DATA_LOADER", false);
    successHandler('Status Update Successfully')
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getUserProfile = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProfile(id).then(
    (response) => {
      commit("USER_PROFILE", response.data);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};



export const UserProfileUpdate = async ({ commit }, {id,  firstName, lastName, mobile, password,delay,level2,level3 }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.UserProfileUpdate(id,  firstName, lastName, mobile, password,delay,level2,level3).then(
    () => {
      commit("SET_DATA_LOADER", false);
      successHandler('Update Successfully')
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const updateUser = async ({ commit ,state}, { id,startTime,endTime,status }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.updateUserProjectTime(id,startTime,endTime,status).then(
    () => {
      state.updateUserModal=false
      commit("SET_LOADER", false, { root: true });
      commit("UPDATE_PROJECT_STATUS",{id,status,startTime,endTime})
      successHandler('Project Updated Successfully')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};
