import moment from 'moment'

export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};
export const SET_PROFILE=(state,data)=>{
  state.createdAt=data.createdAt
  state.createdBy=data.createdBy
  state.email=data.email
  state.id=data.id
  state.keys=data.keys
  state.lastStatus=data.lastStatus
  state.loginId=data.loginId
  state.name=data.name
  state.phoneNumber=data.phoneNumber
  state.roles=data.roles
  state.status=data.status
  state.totalProject=data.totalProject
  state.updatedAt=data.updatedAt
  state.userProject=data.userProject
}
export const SET_DATA = (state, data) => {
  state.data = data[0];
  state.total = data[1];
};

export const PUSH_DATA = (state, data) => {
  state.data.push(...data[0]);
};

export const SET_USER_FORMDATA = (state, data) => {
  state.userFormData=data.form
  state.excelData=[]
  data.form.forEach(item => {
    // serialNo
    if(item.serialNo == item.formCollection?.serialNo){ 
      state.newserialNo = "<p style='color: green'>" + item.serialNo + "</p>";
    } else { 
      state.newserialNo = "<p style='color: red'>" + item.serialNo + "</p>";
    }
    // Title
    if(item.title == item.formCollection?.title){ 
      state.newtitle = "<p style='color: green'>" + item.title + "</p>";
    } else { 
      state.newtitle = "<p style='color: red'>" + item.title + "</p>";
    }
    // First Name
    if(item.firstName == item.formCollection?.firstName){ 
      state.newfirstName = "<p style='color: green'>" + item.firstName + "</p>";
    } else { 
      state.newfirstName = "<p style='color: red'>" + item.firstName + "</p>";
    }
    // Last Name
    if(item.lastName == item.formCollection?.lastName){ 
      state.newlastName = "<p style='color: green'>" + item.lastName + "</p>";
    } else { 
      state.newlastName = "<p style='color: red'>" + item.lastName + "</p>";
    }
    // Initial
    if(item.initial == item.formCollection?.initial){ 
      state.newinitial = "<p style='color: green'>" + item.initial + "</p>";
    } else { 
      state.newinitial = "<p style='color: red'>" + item.initial + "</p>";
    }
    // Email
    if(item.email == item.formCollection?.email){ 
      state.newemail = "<p style='color: green'>" + item.email + "</p>";
    } else { 
      state.newemail = "<p style='color: red'>" + item.email + "</p>";
    }
    // Father Name
    if(item.fatherName == item.formCollection?.fatherName){ 
      state.newfatherName = "<p style='color: green'>" + item.fatherName + "</p>";
    } else { 
      state.newfatherName = "<p style='color: red'>" + item.fatherName + "</p>";
    }
    // DOB
    if(item.dob == item.formCollection?.dob){ 
      state.newdob = "<p style='color: green'>" + item.dob + "</p>";
    } else { 
      state.newdob = "<p style='color: red'>" + item.dob + "</p>";
    }
    // Gender
    if(item.gender == item.formCollection?.gender){ 
      state.newgender = "<p style='color: green'>" + item.gender + "</p>";
    } else { 
      state.newgender = "<p style='color: red'>" + item.gender + "</p>";
    }
    // Profession
    if(item.profession == item.formCollection?.profession){ 
      state.newprofession = "<p style='color: green'>" + item.profession + "</p>";
    } else { 
      state.newprofession = "<p style='color: red'>" + item.profession + "</p>";
    }
    // Mailing Street
    if(item.mailingStreet == item.formCollection?.mailingStreet){ 
      state.newmailingStreet = "<p style='color: green'>" + item.mailingStreet + "</p>";
    } else { 
      state.newmailingStreet = "<p style='color: red'>" + item.mailingStreet + "</p>";
    }
    // Mailing City
    if(item.mailingCity == item.formCollection?.mailingCity){ 
      state.newmailingCity = "<p style='color: green'>" + item.mailingCity + "</p>";
    } else { 
      state.newmailingCity = "<p style='color: red'>" + item.mailingCity + "</p>";
    }
    // Mailing Postal Code
    if(item.mailingPostalCode == item.formCollection?.mailingPostalCode){ 
      state.newmailingPostalCode = "<p style='color: green'>" + item.mailingPostalCode + "</p>";
    } else { 
      state.newmailingPostalCode = "<p style='color: red'>" + item.mailingPostalCode + "</p>";
    }
    // Mailing Country
    if(item.mailingCountry == item.formCollection?.mailingCountry){ 
      state.newmailingCountry = "<p style='color: green'>" + item.mailingCountry + "</p>";
    } else { 
      state.newmailingCountry = "<p style='color: red'>" + item.mailingCountry + "</p>";
    }
    // Service Provider
    if(item.serviceProvider == item.formCollection?.serviceProvider){ 
      state.newserviceProvider = "<p style='color: green'>" + item.serviceProvider + "</p>";
    } else { 
      state.newserviceProvider = "<p style='color: red'>" + item.serviceProvider + "</p>";
    }
    // File No
    if(item.fileNo == item.formCollection?.fileNo){ 
      state.newfileNo = "<p style='color: green'>" + item.fileNo + "</p>";
    } else { 
      state.newfileNo = "<p style='color: red'>" + item.fileNo + "</p>";
    }
    // Reference No
    if(item.referenceNo == item.formCollection?.referenceNo){ 
      state.newreferenceNo = "<p style='color: green'>" + item.referenceNo + "</p>";
    } else { 
      state.newreferenceNo = "<p style='color: red'>" + item.referenceNo + "</p>";
    }
    // Sim No
    if(item.simNo == item.formCollection?.simNo){ 
      state.newsimNo = "<p style='color: green'>" + item.simNo + "</p>";
    } else { 
      state.newsimNo = "<p style='color: red'>" + item.simNo + "</p>";
    }
    // Type Of Network
    if(item.typeOfNetwork == item.formCollection?.typeOfNetwork){ 
      state.newtypeOfNetwork = "<p style='color: green'>" + item.typeOfNetwork + "</p>";
    } else { 
      state.newtypeOfNetwork = "<p style='color: red'>" + item.typeOfNetwork + "</p>";
    }
    // Cell Model No
    if(item.cellModelNo == item.formCollection?.cellModelNo){ 
      state.newcellModelNo = "<p style='color: green'>" + item.cellModelNo + "</p>";
    } else { 
      state.newcellModelNo = "<p style='color: red'>" + item.cellModelNo + "</p>";
    }
    // IMEI 1
    if(item.imei1 == item.formCollection?.imei1){ 
      state.newimei1 = "<p style='color: green'>" + item.imei1 + "</p>";
    } else { 
      state.newimei1 = "<p style='color: red'>" + item.imei1 + "</p>";
    }
    // IMEI 2
    if(item.imei2 == item.formCollection?.imei2){ 
      state.newimei2 = "<p style='color: green'>" + item.imei2 + "</p>";
    } else { 
      state.newimei2 = "<p style='color: red'>" + item.imei2 + "</p>";
    }
    // Type Of Plan
    if(item.typeOfPlan == item.formCollection?.typeOfPlan){ 
      state.newtypeOfPlan = "<p style='color: green'>" + item.typeOfPlan + "</p>";
    } else { 
      state.newtypeOfPlan = "<p style='color: red'>" + item.typeOfPlan + "</p>";
    }
    // Credit Card Type
    if(item.creditCardType == item.formCollection?.creditCardType){ 
      state.newcreditCardType = "<p style='color: green'>" + item.creditCardType + "</p>";
    } else { 
      state.newcreditCardType = "<p style='color: red'>" + item.creditCardType + "</p>";
    }
    // Contract Value
    if(item.contractValue == item.formCollection?.contractValue){ 
      state.newcontractValue = "<p style='color: green'>" + item.contractValue + "</p>";
    } else { 
      state.newcontractValue = "<p style='color: red'>" + item.contractValue + "</p>";
    }
    // Date Of Issue
    if(item.dateOfIssue == item.formCollection?.dateOfIssue){ 
      state.newdateOfIssue = "<p style='color: green'>" + item.dateOfIssue + "</p>";
    } else { 
      state.newdateOfIssue = "<p style='color: red'>" + item.dateOfIssue + "</p>";
    }
    // Date Of Renewal
    if(item.dateOfRenewal == item.formCollection?.dateOfRenewal){ 
      state.newdateOfRenewal = "<p style='color: green'>" + item.dateOfRenewal + "</p>";
    } else { 
      state.newdateOfRenewal = "<p style='color: red'>" + item.dateOfRenewal + "</p>";
    }
    // Installment
    if(item.installment == item.formCollection?.installment){ 
      state.newinstallment = "<p style='color: green'>" + item.installment + "</p>";
    } else { 
      state.newinstallment = "<p style='color: red'>" + item.installment + "</p>";
    }
    // Amount In Words
    if(item.amountInWords == item.formCollection?.amountInWords){ 
      state.newamountInWords = "<p style='color: green'>" + item.amountInWords + "</p>";
    } else { 
      state.newamountInWords = "<p style='color: red'>" + item.amountInWords + "</p>";
    }
    // Remarks
    if(item.remarks == item.formCollection?.remarks){ 
      state.newremarks = "<p style='color: green'>" + item.remarks + "</p>";
    } else { 
      state.newremarks = "<p style='color: red'>" + item.remarks + "</p>";
    }

		state.excelData.push({
			"Serial No": item.formCollection?.serialNo +''+ state.newserialNo,
      "Title": item.formCollection?.title +''+ state.newtitle,
      "First Name": item.formCollection?.firstName +''+ state.newfirstName,
			"Last Name": item.formCollection?.lastName +''+ state.newlastName,
			"Initial": item.formCollection?.initial +''+ state.newinitial,
      "Email": item.formCollection?.email +''+ state.newemail,
			"Father Name": item.formCollection?.fatherName +''+ state.newfatherName,
			"DOB": item.formCollection?.dob +''+ state.newdob,
			"Gender": item.formCollection?.gender +''+ state.newgender,
      "Profession": item.formCollection?.profession +''+ state.newprofession,
			"Mailing Street": item.formCollection?.mailingStreet +''+ state.newmailingStreet,
			"Mailing City": item.formCollection?.mailingCity +''+ state.newmailingCity,
			"Mailing Postal Code": item.formCollection?.mailingPostalCode +''+ state.newmailingPostalCode,
			"Mailing Country": item.formCollection?.mailingCountry +''+ state.newmailingCountry,
			"Service Provider": item.formCollection?.serviceProvider +''+ state.newserviceProvider,
			"File No": item.formCollection?.fileNo +''+ state.newfileNo,
			"Reference No": item.formCollection?.referenceNo +''+ state.newreferenceNo,
			"Sim No": item.formCollection?.simNo +''+ state.newsimNo,
			"Type Of Network": item.formCollection?.typeOfNetwork +''+ state.newtypeOfNetwork,
			"Cell Model No": item.formCollection?.cellModelNo +''+ state.newcellModelNo,
			"IMEI 1": item.formCollection?.imei1 +''+ state.newimei1,
			"IMEI 2": item.formCollection?.imei2 +''+ state.newimei2,
			"Type Of Plan": item.formCollection?.typeOfPlan +''+ state.newtypeOfPlan,
			"Credit Card Type": item.formCollection?.creditCardType +''+ state.newcreditCardType,
			"Contract Value": item.formCollection?.contractValue +''+ state.newcontractValue,
			"Date Of Issue": item.formCollection?.dateOfIssue +''+ state.newdateOfIssue,
			"Date Of Renewal": item.formCollection?.dateOfRenewal +''+ state.newdateOfRenewal,
			"Installment": item.formCollection?.installment +''+ state.newinstallment,
			"Amount In Words": item.formCollection?.amountInWords +''+ state.newamountInWords,
			"Remarks": item.formCollection?.remarks +''+ state.newremarks,
		})
	})
};

export const PROJECT_DETAILS = (state, data) => {
  state.accountId=data.accountId
  state.accuracy=data.accuracy
  state.createdAt=data.createdAt
  state.earning=data.earning
  state.desc=data.desc
  state.endDateEdit= moment(data.endDate).format('YYYY-MM-DDThh:mm')
  state.endDate= moment(data.endDate).format('MM/DD/YYYY hh:mm:ss a')
  state.file1=data.file1
  state.file2=data.file2
  state.file3=data.file3
  state.file4=data.file4
  state.fileName1=data.fileName1
  state.fileName2=data.fileName2
  state.fileName3=data.fileName3
  state.fileName4=data.fileName4
  state.id=data.id
  state.level=data.level
  state.levelObj={id:data.level,name:data.level}
  state.overallAccuracy=data.overallAccuracy
  state.price=data.price
  state.quantity=data.quantity
  state.right=data.right
  state.skipRemaining=data.skipRemaining 
  state.skipped=data.skipped
  state.startDateEdit= moment(data.startDate).format('YYYY-MM-DDThh:mm')
  state.startDate= moment(data.startDate).format('MM/DD/YYYY hh:mm:ss a')
  state.status=data.status
  state.title=data.title
  state.titleObj={id:data.title,name:data.title}
  state.tnc=data.tnc
  state.type=data.type
  state.typeObj={id:data.type,name:data.type}
  state.updatedAt=data.updatedAt
  state.updatedBy=data.updatedBy
  state.userDetailId=data.userDetailId
  state.wrong=data.wrong
};

export const SET_UPDATE_STAFF = (state, data) => {
  const objIndex = state.data.findIndex((obj) => obj.id === data.id);
  state.data[objIndex].designation = data.designation;
  state.data[objIndex].username = data.username;
};


