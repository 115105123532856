import { errorHandler, successHandler } from "../../../services/_helper";
import { apiServices } from "../../../services/api.service";

export const getAdmin = async ({ commit }, { limit, offset, search, status, role }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getAdmin(limit, offset, search, status,role).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
			commit('SET_ADMIN', response.data.result);
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getUser = async ({ commit }, { limit, offset, search, status,role,projectStatus }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUser(limit, offset, search, status,role,projectStatus).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_LOAD_MORE', response.data.total > offset + limit);
			if (+offset > 0) {
				commit('SET_POSTS2', response.data.result);
			} else {
				commit('SET_POSTS1', response.data);
			}
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getSubUser = async ({ commit }, { limit, offset, search, status,role,projectStatus,accountId }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getSubUser(limit, offset, search, status,role,projectStatus,accountId).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_LOAD_MORE', response.data.total > offset + limit);
			if (+offset > 0) {
				commit('PUSH_SUB', response.data.result);
			} else {
				commit('SET_SUB', response.data);
			}
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getUserProfile = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProfile(id).then(
    (response) => {
      commit("USER_PROFILE", response.data);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const StatusChange = async ({ commit }, { id,status }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.StatusChange(id,status).then(
    (response) => {
      commit("UPDATE_STATUS", response.data);
      commit("SET_DATA_LOADER", false);
    successHandler('Status Update Successfully')
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const UserProfileUpdate = async ({ commit }, {id,  firstName, lastName, mobile, password,delay,level2,level3 }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.UserProfileUpdate(id,  firstName, lastName, mobile, password,delay,level2,level3).then(
    () => {
      commit("SET_DATA_LOADER", false);
      successHandler('Update Successfully')
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const addUser = async ({ commit ,state, dispatch}, { subAdminId, subAdmin, phoneNumber,name,email,role,startDate,duration,endDate }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.addUser(subAdminId, subAdmin, phoneNumber,name,email,role,startDate,duration,endDate).then(
    (response) => {
      state.addUserModal=false
      // send mail
      dispatch("sendmail", {receiverName: name, receiverEmail: email, password: response.data.pass, supportno: '7318812165'})
      dispatch('getUser',{limit:10,offset:0,search:"",status:"ACTIVE",role:'USER',projectStatus:'ACTIVE'})
      commit("SET_LOADER", false, { root: true });
      successHandler('User Added Successfully')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};

export const updateUser = async ({ commit ,state}, { id, startTime, duration, endTime, status }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.updateUserProjectTime(id, startTime, duration, endTime, status).then(
    () => {
      state.updateUserModal=false
      commit("SET_LOADER", false, { root: true });
      commit("UPDATE_PROJECT_STATUS",{id,status,startTime,duration,endTime})
      successHandler('Project Updated Successfully')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};

export const getUserProject = async ({ commit }, { limit, offset, search,status,accountId }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProject(limit, offset, search,status,accountId).then(
    (response) => {
      commit("USER_PROJECT", response.data);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const ProjectDelete = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.ProjectDelete(id).then(
    () => {
      commit("DELETE_PROJECT", id);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

// send register email for register time
export const sendmail = async ({ commit }, { receiverName, receiverEmail, password, supportno }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.sendmail(receiverName, receiverEmail, password, supportno).then(
		() => {
			successHandler('Send email Successfull')
			commit("SET_LOADER", false, { root: true });
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response)
		}
	);
};